var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dc-login"},[(_vm.login_enabled)?_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('h5',{staticClass:"vb-heading mb-4"},[_vm._v("Inserisci il codice sconto e prenota il tuo viaggio")]),_c('p',[_vm._v(" Troverai lo sconto disponibile al momento del pagamento ")]),_c('p',[_c('ul',{staticClass:"ml-2"},[_c('li',[_vm._v(" Se il Buono ha un valore inferiore al conto finale dovrai saldare la differenza con carta di credito. Non potrai pagare la differenza con il tuo credito welfare. ")]),_c('li',[_vm._v(" Se il Buono ha un valore superiore all'ammontare del pagamento, potrai utilizzare il valore residuo per una futura prenotazione. Riceverai una email di riepilogo. ")]),_c('li',[_vm._v(" All'interno potrai caricare altri Buoni in tuo possesso e cumulare lo sconto. ")])])])]),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('h5',{staticClass:"vb-heading mb-4"},[_vm._v("Inserisci il codice sconto")]),(!_vm.checking_attrs)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('validation-provider',{attrs:{"rules":"required|alpha_num|min:16|max:16","name":"Codice sconto"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":"Codice sconto","label-class":"vb-label"}},[_c('vb-input',{attrs:{"vb_options":{
                autocomplete: 'chrome-off',
                placeholder: 'Es: XXXXXXXXXXXXXXXX',
              }},model:{value:(_vm.discount_code),callback:function ($$v) {_vm.discount_code=$$v},expression:"discount_code"}})],1),_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|alpha_spaces|min:2","name":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":"Nome","label-class":"vb-label"}},[_c('vb-input',{attrs:{"vb_options":{
                autocomplete: 'chrome-off',
                placeholder: 'Nome',
              }},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"alpha_spaces|min:2","name":"Cognome"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":"Cognome","label-class":"vb-label"}},[_c('vb-input',{attrs:{"vb_options":{
                autocomplete: 'chrome-off',
                placeholder: 'Cognome',
              }},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],1),_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{attrs:{"label":"Email","label-class":"vb-label"}},[_c('vb-input',{attrs:{"vb_options":{
                autocomplete: 'chrome-off',
                placeholder: 'email@email.com',
              }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"mt-4"},[_c('validation-provider',{attrs:{"rules":{ required:{ allowFalse: false } },"name":"Condizioni d'uso"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('vb-checkbox',{attrs:{"vb_options":{}},model:{value:(_vm.checkbox_terms),callback:function ($$v) {_vm.checkbox_terms=$$v},expression:"checkbox_terms"}},[_c('router-link',{staticClass:"nounderline",attrs:{"to":{name: 'terms'},"target":"_blank"}},[_vm._v(" Accetto le condizioni d'uso ")])],1),_c('span',{staticClass:"vee--errors mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ required:{ allowFalse: false } },"name":"Privacy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('vb-checkbox',{attrs:{"vb_options":{}},model:{value:(_vm.checkbox_privacy),callback:function ($$v) {_vm.checkbox_privacy=$$v},expression:"checkbox_privacy"}},[_c('router-link',{staticClass:"nounderline",attrs:{"to":{name: 'privacy-policy'},"target":"_blank"}},[_vm._v(" Accetto le condizioni di Privacy ")])],1),_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mt-4"},[(!_vm.loading)?_c('div',{staticClass:"loginButton"},[_c('vb-button',{staticClass:"btn-block",attrs:{"vb_options":{
                  class: 'btn-block',
                  disabled: invalid,
                  //size: 'lg',
                  text: 'Carica'
                }},on:{"click":() => {
                  if (_vm.recaptcha_enabled) {
                    _vm.recaptcha()
                    return
                  }
                  _vm.dc_login_handler()
                }}})],1):_c('loader')],1)]}}],null,false,4103515374)}):_vm._e()],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }