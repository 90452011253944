<template>
  <div class="dc-login">
    <b-container v-if="login_enabled">
      <b-row>
        <b-col sm="12" md="6" lg="4" offset-lg="1">
          <h5 class="vb-heading mb-4">Inserisci il codice sconto e prenota il tuo viaggio</h5>
          <p>
            Troverai lo sconto disponibile al momento del pagamento
          </p>
          <p>
            <ul class="ml-2">
              <li>
                Se il Buono ha un valore inferiore al conto finale dovrai saldare la differenza con carta di credito. Non potrai pagare la differenza con il tuo credito welfare.
              </li>
              <li>
                Se il Buono ha un valore superiore all'ammontare del pagamento, potrai utilizzare il valore residuo per una futura prenotazione. Riceverai una email di riepilogo.
              </li>
              <li>
                All'interno potrai caricare altri Buoni in tuo possesso e cumulare lo sconto.
              </li>
            </ul>
          </p>
        </b-col>
        <b-col sm="12" md="6" lg="4" offset-lg="1">
          <h5 class="vb-heading mb-4">Inserisci il codice sconto</h5>
          <validation-observer v-if="!checking_attrs" v-slot="{ invalid }">
            <validation-provider rules="required|alpha_num|min:16|max:16" name="Codice sconto" v-slot="{ errors }">
              <b-form-group label="Codice sconto" label-class="vb-label">
                <vb-input v-model="discount_code" :vb_options="{
                  autocomplete: 'chrome-off',
                  placeholder: 'Es: XXXXXXXXXXXXXXXX',
                }"/>
              </b-form-group>
              <span class="vee--errors">{{ errors[0] }}</span>
            </validation-provider>
            <validation-provider rules="required|alpha_spaces|min:2" name="Nome" v-slot="{ errors }">
              <b-form-group label="Nome" label-class="vb-label">
                <vb-input v-model="name" :vb_options="{
                  autocomplete: 'chrome-off',
                  placeholder: 'Nome',
                }"/>
              </b-form-group>
              <span class="vee--errors">{{ errors[0] }}</span>
            </validation-provider>
            <validation-provider rules="alpha_spaces|min:2" name="Cognome" v-slot="{ errors }">
              <b-form-group label="Cognome" label-class="vb-label">
                <vb-input v-model="surname" :vb_options="{
                  autocomplete: 'chrome-off',
                  placeholder: 'Cognome',
                }"/>
              </b-form-group>
              <span class="vee--errors">{{ errors[0] }}</span>
            </validation-provider>
            <validation-provider rules="required|email" name="Email" v-slot="{ errors }">
              <b-form-group label="Email" label-class="vb-label">
                <vb-input v-model="email" :vb_options="{
                  autocomplete: 'chrome-off',
                  placeholder: 'email@email.com',
                }"/>
              </b-form-group>
              <span class="vee--errors">{{ errors[0] }}</span>
            </validation-provider>

            <div class="mt-4">
              <validation-provider :rules="{ required:{ allowFalse: false } }" name="Condizioni d'uso" v-slot="{ errors }">
                <vb-checkbox
                  v-model="checkbox_terms"
                  :vb_options="{}">
                    <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                      Accetto le condizioni d'uso
                    </router-link>
                </vb-checkbox>
                <span class="vee--errors mb-2">{{ errors[0] }}</span>
              </validation-provider>
              <validation-provider :rules="{ required:{ allowFalse: false } }" name="Privacy" v-slot="{ errors }">
                <vb-checkbox
                  v-model="checkbox_privacy"
                  :vb_options="{}">
                    <router-link :to="{name: 'privacy-policy'}" target="_blank" class="nounderline">
                      Accetto le condizioni di Privacy
                    </router-link>
                </vb-checkbox>
                <span class="vee--errors">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="mt-4">
              <div class="loginButton" v-if="!loading">
                <vb-button
                  class="btn-block"
                  :vb_options="{
                    class: 'btn-block',
                    disabled: invalid,
                    //size: 'lg',
                    text: 'Carica'
                  }"
                  @click="() => {
                    if (recaptcha_enabled) {
                      recaptcha()
                      return
                    }
                    dc_login_handler()
                  }
                "/>
              </div>
              <loader v-else />
            </div>

          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue' // per VueRecaptcha
import { service_handler } from '@/services'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default {
  name: 'dc-sso',
  components: {
  },
  props: {
    discount_code_url: {
      type: String,
      default: '',
    },
    name_url: {
      type: String,
      default: '',
    },
    surname_url: {
      type: String,
      default: '',
    },
    email_url: {
      type: String,
      default: '',
    },
  },
  created() {
  },
  data() {
    return {
      login_enabled: this.get_config().guiSettings.allowDcLogin,
      recaptcha_enabled: this.get_config().recaptcha.enabled,
      checking_attrs: true,
      loading: false,
      name: '',
      surname: '',
      email: '',
      discount_code: '',
      checkbox_terms: false,
      checkbox_privacy: false,
      recaptcha_token: '',
    }
  },
  mounted() {
    if (!this.login_enabled) {
      let data = {
        msg: 'Siamo spiacenti, non è possibile effettuare il login con il codice sconto',
        closeText: 'Torna alla homepage',
      }
      data.routerPush = 'homepage'
      window.eventBus.$emit('got_modal_notification', data)
      return
    }

    if (this.get_config().recaptcha.enabled) {
      Vue.use(VueReCaptcha, {
        siteKey: this.get_config().recaptcha.siteKey,
      })
    }
    
    // http://localhost:8666/ho-un-buono/Dgbtc04hv4mjAxJy/giuseppe/cardamone/test@email.it/ --> OCCHIO: trailing slash obbligatorio!
    if (this.discount_code_url !== '') this.discount_code = this.discount_code_url
    if (this.name_url !== '') this.name = this.name_url
    if (this.surname_url !== '') this.surname = this.surname_url
    if (this.email_url !== '') this.email = this.email_url
    this.checking_attrs = false
  },
  methods: {
    async recaptcha() {
      this.loading = true
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      this.recaptcha_token = token
      this.dc_login_handler()
    },
    dc_login_handler() {
      this.loading = true
      service_handler('dcLogin', {
        discountCode: this.discount_code,
        name: this.name,
        surname: this.surname,
        email: this.email,
        recaptcha: this.recaptcha_token,
      }).then(
        (success) => {
          if (success.data && success.data.token) window.location.href = "/?token=" + success.data.token
          else this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    },
  },
};
</script>


<style lang="scss">
.dc-login {
  margin:2*$grid-gutter-width 0;
  @include media-breakpoint-up(sm) {
    //margin-top:4*$grid-gutter-width;
  }
}
</style>